import React from "react";
import classes from "./Test.module.css";
import global from "./index.module.css";
import file from "./intrebari.json";
import axios from "axios";
import { useState } from "react";
function Test() {
  const API_URL = "/api/answers";
  const [details, setDetails] = useState({
    counter: 0,
    preparedCounter: 0,
    empthy: "",
    selection: "",
    altele: false,
  });
  const [final, setFinal] = useState(false);
  const [youranwer, setYouranwer] = useState([]);
  //handles freeText type
  const FreeText = () => {
    //Checks if input is empthy
    if (details.empthy) {
      //puts your question and answeer here
      let current_answer = {
        question: file[details.counter].description,
        answer: details.empthy,
      };
      setYouranwer((prevState) => [...prevState, current_answer]);
      if (file[details.counter].nextQuestion) {
        setDetails((prevState) => ({
          ...prevState,
          counter: file[details.counter].nextQuestion,
          selection: "",
        }));
      }
      else {
        setDetails((prevState) => ({
          ...prevState,
          counter: details.counter + 1,
          empthy: "",
        }));
      }
    }
  };
  const Trimite = async () => {
    const response = await axios.post(API_URL, { test: youranwer });
    setFinal(true);

    console.log(response);
  };
  //handles MultipleChoice type
  const MultipleChoice = () => {
    if (details.selection) {
      let current_answer = {
        question: file[details.counter].description,
        answer: details.selection,
      };
      setYouranwer((prevState) => [...prevState, current_answer]);
      //Daca exista sectiunea de urmatoare intrebare face asa
      if (file[details.counter].nextQuestion) {
        setDetails((prevState) => ({
          ...prevState,
          counter: file[details.counter].nextQuestion[details.preparedCounter],
          selection: "",
        }));
      }
      if (!file[details.counter].nextQuestion) {
        setDetails((prevState) => ({
          ...prevState,
          counter: details.counter+1,
          selection: "",
        }));
      }
    }
  };
  const MultipleChoiceWithOther = () => {
    console.log(details.selection);
    if (details.selection === "altele" && details.empthy) {
      setDetails((prevState) => ({ ...prevState, altele: false }));
      let current_answer = {
        question: file[details.counter].description,
        answer: `Sectiunea ${details.selection}: ${details.empthy}`,
      };
      //daca fisierul nu are sectiune de next question
      if (!file[details.counter].nextQuestion) {
        setYouranwer((prevState) => [...prevState, current_answer]);
        setDetails((prevState) => ({
          ...prevState,
          counter: details.counter + 1,
          empthy: "",
          selection: "",
        }));
      } else {
        if (file[details.counter].nextQuestion.length > 1) {
          setDetails((prevState) => ({
            ...prevState,
            counter:
              file[details.counter].nextQuestion[details.preparedCounter],
            selection: "",
            empthy: "",
          }));
        } else {
          setDetails((prevState) => ({
            ...prevState,
            counter: file[details.counter].nextQuestion,
            selection: "",
            empthy: "",
          }));
        }
      }
    } else if (details.selection && details.selection !== "altele") {
      let current_answer = {
        question: file[details.counter].description,
        answer: details.selection,
      };
      setYouranwer((prevState) => [...prevState, current_answer]);
      //Daca exista sectiunea de urmatoare intrebare face asa
      if (file[details.counter].nextQuestion) {
        if (file[details.counter].nextQuestion.length > 1) {
          setDetails((prevState) => ({
            ...prevState,
            counter:
              file[details.counter].nextQuestion[details.preparedCounter],
            selection: "",
            empthy: "",
          }));
        } else {
          setDetails((prevState) => ({
            ...prevState,
            counter: file[details.counter].nextQuestion,
            selection: "",
            empthy: "",
          }));
        }
      } else {
        setDetails((prevState) => ({
          ...prevState,
          counter: details.counter + 1,
          selection: "",
        }));
      }
    }
  };
  const MultipleChoiceWithObservations = () => {
    if (details.selection != "") {
      let current_answer = {
        question: file[details.counter].description,
        answer: details.selection,
        Observation: details.empthy,
      };
      setYouranwer((prevState) => [...prevState, current_answer]);
      //Daca exista sectiunea de urmatoare intrebare face asa
      if (!file[details.counter].nextQuestion) {
        setDetails((prevState) => ({
          ...prevState,
          counter: details.counter + 1,
          selection: "",
          empthy:""
        }));
      }
    }
  };
  const descriptiveText = () => {
    if (file[details.counter].nextQuestion) {
      setDetails((prevState) => ({
        ...prevState,
        empthy: "",
        selection: "",
        preparedCounter: 4,
        counter: file[details.counter].nextQuestion,
      }));
    }
    setDetails((prevState) => ({
      ...prevState,
      empthy: "",
      selection: "",
      preparedCounter: 4,
      counter: details.counter + 1,
    }));
  };
  //saves your answer and sends you to the next question
  const toTheNext = () => {
    //Checks the type
    switch (file[details.counter].type) {
      case "freeText":
        FreeText();
        break;
      case "multipleChoice":
        MultipleChoice();
        break;
      case "multipleChoiceWithOther":
        MultipleChoiceWithOther();
        break;
      case "multipleChoiceWithObservations":
        MultipleChoiceWithObservations();
        break;
      case "descriptiveText":
        descriptiveText();
        break;
    }
  };
  //updates your writing
  const Write = (e) => {
    setDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  //unifies a vector into a string
  const Reunite_text = (changed_Name) => {
    let TextReunited = "";
    changed_Name.map((word, anc) => {
      if (anc < changed_Name.length - 1 && anc !== 0) {
        TextReunited = TextReunited + word + " ";
      } else if (anc == changed_Name.length - 1) {
        TextReunited = TextReunited + word;
      }
    });
    return TextReunited;
  };
  const Reincepe = (e) => {
    window.location.href="/";
  };
  //selects the choice you touched
  const Selection = (e) => {
    let changed_Name = e.target.name.split(" ");
    let textUnited = Reunite_text(changed_Name);
    setDetails((prevState) => ({
      ...prevState,
      altele: false,
      empthy: "",
      selection: textUnited,
      preparedCounter: changed_Name[0],
    }));
  };
  const SelectionAltele = (e) => {
    setDetails((prevState) => ({
      ...prevState,
      selection: "altele",
      altele: true,
      preparedCounter:4
    }));
  };
  return (
    <div className={classes.app}>
      {details.counter !== -1 ? (
        <div>
          <div className={classes.home}>
            {file[details.counter].description}
          </div>

          {file[details.counter].type === "freeText" && (
            <input
              value={details.empthy}
              className={classes.write}
              name="empthy"
              onChange={Write}
            />
          )}

          {file[details.counter].type === "multipleChoice" && (
            <div className={classes.options}>
              {file[details.counter].options.map((option, anc) => (
                <button
                  className={`${option == details.selection && classes.highlight
                    } ${classes.stylee}`}
                  name={`${anc} ${option}`}
                  key={anc}
                  onClick={Selection}
                >
                  {option}
                </button>
              ))}
            </div>
          )}
          {file[details.counter].type === "multipleChoiceWithOther" && (
            <div className={classes.options}>
              {file[details.counter].options.map((option, anc) => (
                <button
                  className={`${option == details.selection && classes.highlight
                    } ${classes.stylee}`}
                  name={`${anc} ${option}`}
                  key={anc}
                  onClick={Selection}
                >
                  {option}
                </button>
              ))}
              <button
                className={`${"altele" == details.selection && classes.highlight
                  } ${classes.stylee}`}
                onClick={SelectionAltele}
              >
                Altele
              </button>
              <br />
              {details.altele && (
                <input
                  value={details.empthy}
                  className={classes.write}
                  name="empthy"
                  onChange={Write}
                />
              )}
            </div>
          )}
          {file[details.counter].type === "multipleChoiceWithObservations" && (
            <div>
              {file[details.counter].options.map((option, anc) => (
                <button
                  className={`${option == details.selection && classes.highlight
                    } ${classes.stylee}`}
                  name={`${anc} ${option}`}
                  key={anc}
                  onClick={Selection}
                >
                  {option}
                </button>
              ))}
              <br />
              <input
                className={classes.write}
                placeholder="Observatie aditionala"
                value={details.empthy}
                name="empthy"
                onChange={Write}
              />
            </div>
          )}
          <br />
          <button className={global.submit} onClick={toTheNext}>
            Next
          </button>
        </div>
      ) : (
        <div>
          {final && (
            <div>
              <div className={classes.home}>
                Multumim pentru timpul si feedback-ul acordat
              </div>
              <button className={global.submit} onClick={Reincepe}>
                Reincepe chestionarul
              </button>
            </div>
          )}
          {!final && (
            <button className={global.submit} onClick={Trimite}>
              Trimiteti raspunsurile
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default Test;
