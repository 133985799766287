import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Test from "./Test";
import Welcome from "./Welcome";
export default function App() {
  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path="/test" element={<Test />}></Route>
          <Route path="/" element={<Welcome />}></Route>
        </Routes>
      </Router>
    </Fragment>
  );
}
